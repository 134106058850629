
import {
  Configuration, ContextPayloadDto,
  HolderApi, HolderState,
  IdentProviderApi, OrderAuthRedirects, PersonRequestStateEnum,
  SSOUserManagementApi
} from "../../api-clients/core-service-api-react";
import { getContext, getDefaultHeaders } from "../../utils/helpers";
import { RootState } from '../store';

const apiConfig= new Configuration({
  basePath: window?._env_?.CREFO_TRUSTEE_API ?? '',
})

const api = new HolderApi(apiConfig)

const webhooksApi = new IdentProviderApi(apiConfig)

async function getHolderState(
  token: string,
  state: RootState
): Promise<HolderState> {
  const { orderId, did } = getContext(state);
  const headers = getDefaultHeaders(token);

  let result;
  try {
    result = await api.holderGatewayControllerGetState(did, orderId, {
      headers,
    })
  } catch(e) {
    console.error(e);
    throw e;
  }

  return result.data;
}

async function updateHolderState(
  token: string,
  state: RootState,
  payload: ContextPayloadDto
): Promise<HolderState> {
  const { orderId, did } = getContext(state);
  const headers = getDefaultHeaders(token);

  const result = await api.holderGatewayControllerUpdateState(did, orderId, payload, { headers })

  return result.data;
}

async function personVerificationFinalization(
  token: string,
  state: RootState
): Promise<void> {
  const headers = getDefaultHeaders(token);
  const request = state?.ident?.data?.requests?.find(
    (req) => req.state === PersonRequestStateEnum.CustomerPostprocessing
  );
  const requestId = request?.id;
  const externalOrderId = request?.actions[0].value.OrderID;

  await api.webhooksGatewayControllerWebhookPersonVerificationFinalization({
    headers,
    params: {
      requestId,
      externalOrderId,
    }
  });
}

async function getVerifiablePresentations(
  token: string,
  state: RootState,
  jwts: string[]
): Promise<void> {
  const { orderId, did } = getContext(state);
  const headers = getDefaultHeaders(token);

  const result =
      await api.holderGatewayControllerGetVerifiablePresentations(did, orderId, { headers, data: { jwts } })

  return result.data;
}

async function getOrderRedirects(
    orderId: string,
): Promise<OrderAuthRedirects> {
  const result =
      await api.holderGatewayControllerGetOrderAuthConfig(orderId)

  return result.data;
}

async function releaseCredentials(
  token: string,
  state: RootState
): Promise<string> {
  const { orderId, did } = getContext(state);
  const headers = getDefaultHeaders(token);

  const result = await api.holderGatewayControllerReleaseCredentials(did, orderId, { headers })

  return result.data as any;
}

async function abort(token: string, state: RootState): Promise<string> {
  const { orderId, did } = getContext(state);
  const headers = getDefaultHeaders(token);

  const result = await api.holderGatewayControllerAbort(did, orderId, { headers });

  return result.data as any;
}

async function triggerPinLetterWebhook(
  token: string,
  state: RootState
): Promise<void> {
  const headers = getDefaultHeaders(token);

  const request = state?.ident?.data?.requests?.find(
    (req) => req.state === PersonRequestStateEnum.Open
  );
  const requestId = request?.id;
  const pinOrderId = request?.actions[0].value.OrderID;

  const isRequestIdProvided = requestId && requestId?.length > 0;
  const isPinOrderIdProvided = pinOrderId && pinOrderId.length > 0;
  if (!isRequestIdProvided || !isPinOrderIdProvided) {
    return;
  }
  const params = { requestId: requestId, pinOrderId: pinOrderId };
  await webhooksApi.webhooksGatewayController({
    headers,
    params,
  })
}

export {
  getHolderState,
  updateHolderState,
  personVerificationFinalization,
  getVerifiablePresentations,
  releaseCredentials,
  abort,
  triggerPinLetterWebhook,
  getOrderRedirects,
};
