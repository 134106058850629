import { useEffect } from 'react';

import {hasAuthParams, useAuth} from "react-oidc-context";
import { useStore } from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import {RoutingStateRoutesEnum} from "../../api-clients/core-service-api-react";
import { CrefoRoute } from '../../interfaces/crefo-route.interface';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  getIdentState,
  selectFlavor,
  selectHolderStateLoaded,
  selectRoutes,
} from '../../state/ident/identSlice';
import {
  manuallyReVerify,
  returnFromIdentService,
  selectManualCompanyAddressCorrection,
  selectManualReVerification,
  selectSubmitted,
} from '../../state/input/inputSlice';
import { selectIsInitialized } from "../../state/targetPlatform/targetPlatformSlice";

interface StateRouterProps {
  children: React.ReactNode;
}

export const StateRouter = ({ children }: StateRouterProps) => {
  const locationState = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const store = useStore();
  const auth = useAuth();
  const submitted = useAppSelector(selectSubmitted);
  const routes = useAppSelector(selectRoutes);
  const flavor = useAppSelector(selectFlavor);
  const holderStateLoaded = useAppSelector(selectHolderStateLoaded);
  const manualReVerification = useAppSelector(selectManualReVerification);
  const targetPlatformInitialized = useAppSelector(selectIsInitialized);
  const manualCompanyAddressCorrection = useAppSelector(
    selectManualCompanyAddressCorrection
  );

  const safeNavigate = (route: CrefoRoute) => {
    if (route !== locationState.pathname) {
      navigate(route, { state: store.getState() });
    }
  };

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    })
  }, [auth.events, auth.signinSilent]);

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect()
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  useEffect(() => {
    // reload state when returning from ident service as state (especially routing state) might have changed
    if (locationState.pathname === CrefoRoute.VERIFICATION) {
      if (!holderStateLoaded || submitted) {
        dispatch(getIdentState());
        dispatch(returnFromIdentService());
        dispatch(manuallyReVerify(false));
        return;
      }
    }

    if (holderStateLoaded) {
      const namedRoutes: CrefoRoute[] = routes.map((route) => {
        switch (route) {
          case RoutingStateRoutesEnum.CompanySearch:
            return CrefoRoute.COMPANY_SEARCH;
          case RoutingStateRoutesEnum.SelectIdentprovider:
            return CrefoRoute.SELECT_IDENT_PROVIDER;
          case RoutingStateRoutesEnum.Verification:
            return CrefoRoute.VERIFICATION;
          case RoutingStateRoutesEnum.ApproveCredentials:
            return CrefoRoute.APPROVE_CREDENTIALS;
          case RoutingStateRoutesEnum.CustomerInputRequired:
            return CrefoRoute.CUSTOMER_INPUT_REQUIRED;
          case RoutingStateRoutesEnum.CredentialsInsufficient:
            return CrefoRoute.CREDENTIALS_INSUFFICIENT;
          case RoutingStateRoutesEnum.MyEsgVerification:
            return CrefoRoute.MY_ESG_VERIFICATION;
          default:
            return CrefoRoute.VERIFICATION;
        }
      });

      if (manualReVerification) {
        namedRoutes.push(CrefoRoute.SELECT_IDENT_PROVIDER);
      } else if (manualCompanyAddressCorrection) {
        namedRoutes.push(CrefoRoute.CUSTOMER_INPUT_REQUIRED);
      }

      // B2C must not route to CompanySearch!
      if (flavor === 'B2C' && locationState.pathname === CrefoRoute.COMPANY_SEARCH) {
        safeNavigate(CrefoRoute.SELECT_IDENT_PROVIDER);
        return;
      }

      // Checks if the current route is allowed, otherwise navigates to an allowed route
      if (
        namedRoutes.length > 0 &&
        !namedRoutes.find((route: CrefoRoute) => route.valueOf() === locationState.pathname)
      ) {
        safeNavigate(namedRoutes[0]);
      }
    }
  }, [
    holderStateLoaded,
    routes,
    locationState.pathname,
    submitted,
    manualReVerification,
  ]);

  useEffect(() => {
    if (auth.isAuthenticated && targetPlatformInitialized) {
      dispatch(getIdentState());
    }
  }, [auth, targetPlatformInitialized]);

  return <>{children}</>;
};
