import { Card, CardMedia, Typography, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import userSvg from '../../assets/images/icons/User.svg';

const signatoryCardStyles = makeStyles({
  card: {
    width: 160,
    height: 160,
    borderRadius: 0,
    padding: 0,
    margin: 10,
  },
  bgImage: {
    width: 160,
    height: 160,
    padding: 0,
    textTransform: 'none',
  },
  credentials: {
    width: 160,
    height: 155,
    paddingLeft: 5,
    paddingBottom: 5,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  selectedTop: {
    marginLeft: 0,
    width: 160,
    height: 5,
    backgroundColor: '#004884',
  },
  regularTop: {
    marginLeft: 0,
    width: 160,
    height: 5,
    backgroundColor: 'white',
  },
  disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  }
});

const SignatoryCardMedia = (props) => {
  const classes = signatoryCardStyles();
  const { validSignatory, id, givenName, familyName, chosenSignatory } = props;

  return <CardMedia image={userSvg} className={`${classes.bgImage} ${(!validSignatory) ? classes.disabled : ''}`}>
    <div
        className={
          id === chosenSignatory?.id ? classes.selectedTop : classes.regularTop
        }
    />
    <div className={classes.credentials}>
      <Typography>{givenName}</Typography>
      <Typography>{familyName}</Typography>
    </div>
  </CardMedia>
};

export const SignatoryCard = (props) => {
  const classes = signatoryCardStyles();
  const {
    id,
    chosenSignatory,
    updateChosenSignatory,
    disabled,
    validSignatory,
  } = props;
  const handleCardClick = () => {
    if (!disabled && validSignatory) {
      updateChosenSignatory(id);
    }
  };

  return (
    <Card
      elevation={id === chosenSignatory?.id ? 6 : 1}
      className={classes.card}
    >
      {(!disabled && validSignatory) ? (
        <CardActionArea onClick={handleCardClick}>
          <SignatoryCardMedia {...props}/>
        </CardActionArea>
      ) : <SignatoryCardMedia validSignatory={false} {...props}/>}
    </Card>
  );
};

SignatoryCard.propTypes = {
  givenName: PropTypes.string.isRequired,
  familyName: PropTypes.string.isRequired,
  chosenSignatory: PropTypes.shape({}),
  updateChosenSignatory: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
