import { useEffect, useState } from 'react';

import { TextField, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { CustomerInputAddress } from './customer-address.interface';
import { CustomerInputData } from './customer-input.interface';
import { Credential, CredentialTypeEnum } from '../../api-clients/core-service-api-react';
import { useAppSelector } from '../../state/hooks';
import {
  selectCompanyCredentials,
  selectPersonCredentials,
} from '../../state/ident/identSlice';

interface AddressInputFieldsProp {
  setCustomerInputData: (address: CustomerInputData) => void;
  isPersonRelated: boolean;
}

export const AddressInputField = ({
  setCustomerInputData,
  isPersonRelated,
}: AddressInputFieldsProp) => {
  const { t } = useTranslation();
  const personCredentials = useAppSelector(selectPersonCredentials);
  const companyCredentials = useAppSelector(selectCompanyCredentials);
  const [address, setAddress] = useState<CustomerInputAddress>({
    streetAddress: '',
    streetNumber: '',
    postalCode: '',
    addressLocality: '',
    addressCountry: '',
  });
  const [streetAddress, setStreetAddress] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [addressLocality, setAddressLocality] = useState('');
  const [addressCountry, setAddressCountry] = useState('');
  const [hasAddressCred, setHasAddressCred] = useState(false);
  const [editable, setEditable] = useState(false);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    let addressCred: Credential | undefined;
    if (isPersonRelated) {
      if (
        personCredentials
          .map((cred) => cred.type)
          .includes(CredentialTypeEnum.Address) &&
        !edited
      ) {
        addressCred = personCredentials.find(
          (cred) => cred.type === CredentialTypeEnum.Address
        );
      }
    } else {
      if (
        companyCredentials
          .map((cred) => cred.type)
          .includes(CredentialTypeEnum.Address) &&
        !edited
      ) {
        addressCred = [...companyCredentials]
          .sort((a, b) => b.updatedAt - a.updatedAt)
          .find((cred) => cred.type === CredentialTypeEnum.Address);
      }
    }
    if (addressCred) {
      setHasAddressCred(true);
      const payload = addressCred?.payload as any;
      const street = payload?.address?.streetAddress?.slice(
        0,
        payload?.address?.streetAddress?.lastIndexOf(' ')
      );
      const number = payload?.address?.streetAddress?.slice(
        payload?.address?.streetAddress?.lastIndexOf(' ') + 1
      );
      setStreetAddress(street);
      setStreetNumber(number);
      setPostalCode(payload?.address?.postalCode);
      setAddressLocality(payload?.address?.addressLocality);
      setAddressCountry(payload?.address?.addressCountry);
      setAddress({
        streetAddress: street,
        streetNumber: number,
        postalCode: payload?.address?.postalCode,
        addressCountry: payload?.address?.addressCountry,
        addressLocality: payload?.address?.addressLocality,
      });
    }
  }, [personCredentials, companyCredentials]);

  useEffect(() => {
    setCustomerInputData({ address });
  }, [address]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEdited(true);
    let addressCred: Credential | undefined;
    if (isPersonRelated) {
      addressCred = personCredentials.find(
        (cred) => cred.type === CredentialTypeEnum.Address
      );
    } else {
      addressCred = companyCredentials.find(
        (cred) => cred.type === CredentialTypeEnum.Address
      );
    }
    let newAddress: CustomerInputAddress;
    if (addressCred) {
      newAddress = {
        streetAddress,
        streetNumber,
        postalCode,
        addressCountry,
        addressLocality,
        [event.target.name]: event?.target?.value,
      };
    } else {
      newAddress = {
        ...address,
        [event.target.name]: event?.target?.value,
      };
    }
    setAddress(newAddress);
  };

  const getAddressChangedText = () => {
    if (isPersonRelated) {
      return (
        <Typography variant='body1' align='left'>
          {t('customerInputRequired.addressChanged1')}
          <a href='#' onClick={() => setEditable(true)}>
            {t('customerInputRequired.addressChanged2')}
          </a>
          {t('customerInputRequired.addressChanged3')}
        </Typography>
      );
    } else {
      return (
        <Typography variant='body1' align='left'>
          {t('customerInputRequiredCompany.addressCompanyChanged1')}
          <a href='#' onClick={() => setEditable(true)}>
            {t('customerInputRequiredCompany.addressCompanyChanged2')}
          </a>
          {t('customerInputRequiredCompany.addressCompanyChanged3')}
        </Typography>
      );
    }
  };

  return (
    <div>
      {!hasAddressCred ? (
        <Typography variant='body1' align='left'>
          {t('customerInputRequired.addressHint')}
        </Typography>
      ) : (
        getAddressChangedText()
      )}
      <br />
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <TextField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStreetAddress(e.target.value);
              handleChange(e);
            }}
            name='streetAddress'
            label={t('customerInputRequired.labelStreetAddress')}
            value={streetAddress}
            variant='outlined'
            fullWidth
            required
            disabled={hasAddressCred && !editable}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStreetNumber(e.target.value);
              handleChange(e);
            }}
            name='streetNumber'
            label={t('customerInputRequired.labelStreetNumber')}
            value={streetNumber}
            variant='outlined'
            fullWidth
            required
            disabled={hasAddressCred && !editable}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPostalCode(e.target.value);
              handleChange(e);
            }}
            name='postalCode'
            label={t('customerInputRequired.labelPostalCode')}
            value={postalCode}
            variant='outlined'
            fullWidth
            required
            disabled={hasAddressCred && !editable}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAddressLocality(e.target.value);
              handleChange(e);
            }}
            name='addressLocality'
            label={t('customerInputRequired.labelAddressLocality')}
            value={addressLocality}
            variant='outlined'
            fullWidth
            required
            disabled={hasAddressCred && !editable}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAddressCountry(e.target.value);
              handleChange(e);
            }}
            name='addressCountry'
            label={t('customerInputRequired.labelAddressCountry')}
            value={addressCountry}
            variant='outlined'
            fullWidth
            required
            disabled={hasAddressCred && !editable}
          />
        </Grid>
      </Grid>
    </div>
  );
};
