import { Button, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { CrefoRoute } from '../../interfaces/crefo-route.interface';
import { NavigationStyles } from './Navigation.styles';

export const RepeatVerificationButton = () => {
  const navigate = useNavigate();
  const classes = NavigationStyles();
  const { t } = useTranslation();
  return (
    <>
      <Hidden smDown>
        <Button
          data-testid='continueBtn'
          className={classes.nextButton}
          onClick={() => {
            navigate(CrefoRoute.COMPANY_SEARCH);
          }}
        >
          {`${t('showVerificationResult.repeatVerification')}`}
        </Button>
      </Hidden>
      <Hidden mdUp>
        <Button
          data-testid='continueBtn'
          className={classes.smallButton}
          onClick={() => {
            navigate(CrefoRoute.COMPANY_SEARCH);
          }}
        >
          {`${t('showVerificationResult.repeatVerification')}`}
        </Button>
      </Hidden>
    </>
  );
};
