import { useCallback, useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { t } from 'i18next';

import {HolderState, IdentRequestDtoSelectedIdentProviderEnum} from "../../api-clients/core-service-api-react";
import { waitForVerificationStyles } from '../../CrefoTheme';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  getIdentState,
  pinOrderWebhook,
  selectOrderPresets,
  sendCredentials,
  updateIdentState,
} from '../../state/ident/identSlice';
import { getCompanies } from '../../state/input/inputSlice';

export const MyEsgVerification = () => {
  const intervalTimer = 1000;
  const dispatch = useAppDispatch();
  const orderPresets = useAppSelector(selectOrderPresets);
  const classes = waitForVerificationStyles();
  let crefoId = orderPresets?.forOrg?.crefoId ?? '';
  const [companyResultStatus, setCompanyResultStatus] = useState('');

  async function getActualIdentState(): Promise<HolderState> {
    const result = new Promise<HolderState>((resolve) => {
      const interval = setInterval(async () => {
        try {
          const response = await dispatch(getIdentState());

          const hasCompanyCredentials =
            response?.payload &&
            (response?.payload as HolderState).data.companyCredentials.length >
              0;

          const hasPersonCredentials =
            response?.payload &&
            (response?.payload as HolderState).data.personCredentials.length >
              0;

          if (hasCompanyCredentials && hasPersonCredentials) {
            const holderState = response.payload as HolderState;
            clearInterval(interval);
            resolve(holderState);
            return;
          }
        } catch (error) {
          console.error(error);
        }
      }, intervalTimer);
    });
    return result;
  }

  const fetchCompany = useCallback(async () => {
    try {
      const companyDispatchResult = await dispatch(getCompanies(crefoId));
      setCompanyResultStatus(companyDispatchResult?.meta?.requestStatus);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [crefoId, dispatch]);

  useEffect(() => {
    async function redirect() {
      if (crefoId === undefined || !crefoId) {
        throw Error('No crefoId provided');
        return;
      }
      await fetchCompany();

      if (companyResultStatus !== 'fulfilled') {
        return;
      }

      let updateIdentDispatchResult = await dispatch(
        updateIdentState({
          customerProvidedData: {},
          provider: IdentRequestDtoSelectedIdentProviderEnum.PinletterIdent,
        })
      );

      if (updateIdentDispatchResult.meta.requestStatus !== 'fulfilled') {
        console.error(
          `Identification update failed`,
          updateIdentDispatchResult
        );
        return;
      }

      let webhookResponse = await dispatch(pinOrderWebhook());

      if (webhookResponse.type !== 'ident/pinOrderWebhook/fulfilled') {
        console.error('Webhook could not be triggered');
        return;
      }

      await getActualIdentState().then(() => {
        return dispatch(sendCredentials());
      });
    }

    redirect();
  }, [fetchCompany, companyResultStatus]);

  return (
    <>
      <CircularProgress size={80} className={classes.myEsgSpinner} />
      <div className={classes.container}>
        <h3 className={classes.headline}>{t('waitForVerification.text2')}</h3>
      </div>
    </>
  );
};
