import { useEffect, useState } from 'react';

import { Grid, Typography, Hidden } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { IdentProviderToCardMapping } from './IdentProviderToCardMapping';
import { SelectIdentProviderStyles } from './SelectIdentProvider.styles';
import { SupportedDocs } from './SupportedDocs';
import {IdentRequestDtoSelectedIdentProviderEnum, PersonRequest} from "../../api-clients/core-service-api-react";
import crefoTheme from '../../CrefoTheme';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  selectFlavor,
  selectHolderStateLoaded,
  selectOfferedIdMethods,
  selectRequestsOpen,
  selectVerifierName,
  updateIdentState,
} from '../../state/ident/identSlice';
import {
  goToIdentService,
  selectChosenSignatory,
} from '../../state/input/inputSlice';
import { DynamicHeightContainer } from '../DynamicHeightContainer/DynamicHeightContainer';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { IdentityCard } from '../IdentityCard/IdentityCard';
import { IdentProviderCard } from '../IdentProviderCard/IdentProviderCard';
import { Navigation } from '../Navigation/Navigation';
import { MobilePopOver } from '../Popups/MobilePopOver';
import { Popup } from '../Popups/Popup';

export const SelectIdentProvider = (props: any) => {
  const classes = SelectIdentProviderStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const verifierName = useAppSelector(selectVerifierName);
  const flavor = useAppSelector(selectFlavor);

  const offeredIdMethods = useAppSelector(selectOfferedIdMethods);

  const [selectedIdentProvider, setSelectedIdentProvider] = useState('');
  const [loading, setLoading] = useState(false);
  const [help, setHelp] = useState(false);
  const [showSupportedDocs, setShowSupportedDocs] = useState<null | string>();
  const [identProviderUrl, setIdentProviderUrl] = useState<string>('');

  const openRequests = useAppSelector(selectRequestsOpen);
  const holderStateLoaded = useAppSelector(selectHolderStateLoaded);
  const chosenSignatory = useAppSelector(selectChosenSignatory);

  useEffect(() => {
    if (selectedIdentProvider && typeof selectedIdentProvider === 'string') {
      createRequest();
    }
  }, [selectedIdentProvider]);

  useEffect(() => {
    if (
      openRequests &&
      openRequests.length > 0 &&
      openRequests.find((req) => req.dataSource === selectedIdentProvider)
    ) {
      const selectedRequest: PersonRequest | undefined = openRequests.find(
        (req) =>
          req.dataSource === selectedIdentProvider &&
          (req.payload.crefoId === chosenSignatory?.crefoId || flavor === 'B2C')
      );
      if (selectedRequest) {
        dispatch(goToIdentService());
        setLoading(false);
        setIdentProviderUrl(selectedRequest?.actions[0]?.value?.TargetURL);
      } else {
        console.info(
          `Request should have been created but no request existing (yet?) or chosen signatory and person in request mismatch. Maybe mocking is enabled to automatically set person in request to mocked data?`
        );
      }
    } else if (flavor === 'B2B' && holderStateLoaded) {
      // TODO: Check when and why this was needed!!!
      // navigate(CrefoRoute.VERIFICATION, { state: store.getState() });
    }
  }, [openRequests, holderStateLoaded]);

  const createRequest = () => {
    setLoading(true);
    dispatch(
      updateIdentState({
        customerProvidedData: {},
        provider: selectedIdentProvider as IdentRequestDtoSelectedIdentProviderEnum,
      })
    );
  };

  return (
    <ThemeProvider theme={crefoTheme}>
      <DynamicHeightContainer>
        <Header
          targetPlatform={verifierName}
          activeStep={2}
          showTargetPlatformLink
          showStepper
        />
        <Grid
          container
          id='content'
          className={`container marginTop ${classes.contentContainer}`}
        >
          <Grid
            lg={12}
            md={12}
            alignItems='flex-start'
            direction='row'
            spacing={0}
            container
            item
          >
            <Grid container item lg={8} md={12}>
              <Grid item md={12} lg={12}>
                <Grid item md={12} lg={12}>
                  <Typography
                    variant='h2'
                    component='h2'
                    align='left'
                    style={{ marginTop: '30px' }}
                  >
                    {t('selectIdentProvider.header')}
                  </Typography>
                </Grid>
                <Grid style={{ marginTop: '20px' }} item md={12}>
                  <Typography align='left'>
                    {t('selectIdentProvider.body1')}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    marginTop: 20,
                  }}
                  spacing={2}
                >
                  {offeredIdMethods &&
                    offeredIdMethods.map((method) => {
                      const mapping = IdentProviderToCardMapping[method];
                      return (
                        <IdentProviderCard
                          key={method}
                          identProvider={method}
                          title={mapping.title}
                          subtitle={t(mapping.subtitle)}
                          image={mapping.image}
                          description={t(mapping.description)}
                          entries={mapping.entries}
                          link={t(mapping.link)}
                          isSelected={selectedIdentProvider === method}
                          setSelectedIdentProvider={setSelectedIdentProvider}
                          setShowSupportedDocs={setShowSupportedDocs}
                        />
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item lg={4} justifyContent='flex-end'>
              <Hidden mdDown>
                <Grid item>
                  <IdentityCard
                    animated={null}
                    idResult={undefined}
                    cwcResult={null}
                  />
                </Grid>
                <Hidden only={['lg', 'sm']}>
                  <Grid item md={6} lg={4}>
                    <Typography variant='body1' align='justify' paragraph>
                      {t('showSearch.belowPersoText')}
                    </Typography>
                  </Grid>
                </Hidden>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} justifyContent='center'>
          <Hidden mdDown>
            <Navigation
              onClick={() => document.location.assign(identProviderUrl)}
              showItmInfo
              loading={loading}
              disabled={!identProviderUrl}
              nextButtonText={t('selectIdentProvider.nextButton')}
              showContinueButton
            />
          </Hidden>
          <Hidden lgUp>
            <Navigation
              onClick={() => document.location.assign(identProviderUrl)}
              showItmInfo={false}
              loading={loading}
              disabled={!identProviderUrl}
              nextButtonText={t('selectIdentProvider.nextButton')}
              showContinueButton
            />
          </Hidden>
          <Footer />
        </Grid>
        {help && (
          <MobilePopOver
            onClickCloseButton={() => {
              setHelp(false);
            }}
          />
        )}
        {showSupportedDocs && (
          <Popup
            title={t('supportedDocs.title')}
            customWidth={'auto'}
            content={<SupportedDocs identProvider={showSupportedDocs} />}
            onClose={() => {
              setShowSupportedDocs(null);
            }}
            fullScreen={false}
          />
        )}
      </DynamicHeightContainer>
    </ThemeProvider>
  );
};
