
import { OrgCredentialAccordion } from './OrgCredentialAccordion';
import { PersonCredentialAccordion } from './PersonCredentialAccordion';
import { VerifiedAccountAccordion } from './VerifiedAccountAccordion';
import {CredentialTypeEnum} from "../../api-clients/core-service-api-react";
import { useAppSelector } from '../../state/hooks';
import {
  selectCompanyCredentials,
  selectPersonCredentials,
  selectRequiredCredentialTypes,
  selectVerifierName,
} from '../../state/ident/identSlice';
import {getCredentialPayload} from "../../utils/helpers";


export const DisplayCredentials = () => {
  const hostname = useAppSelector(selectVerifierName);
  const personCredentials = useAppSelector(selectPersonCredentials);
  const companyCredentials = useAppSelector(selectCompanyCredentials);
  const requiredCredentialTypes = useAppSelector(selectRequiredCredentialTypes);

  // filter VCs to required
  const requiredPersonCredentials = personCredentials.filter((c) =>
    requiredCredentialTypes?.person?.includes(c.type)
  );
  const authRepCredential = personCredentials.find(
    (c) =>
      c.type === CredentialTypeEnum.AuthorizedRepresentative &&
      requiredCredentialTypes?.authorizedRepresentative
  );
  const requiredCompanyCredentials = companyCredentials.filter((c) =>
    requiredCredentialTypes?.org?.includes(c.type)
  );

  // person VCs
  const { givenName, familyName } = getCredentialPayload(personCredentials, CredentialTypeEnum.Name) ?? { givenName: '', familyName: '' };
  const birthdate = getCredentialPayload(personCredentials, CredentialTypeEnum.Birthdate)?.birthdate;
  const personalAddress = getCredentialPayload(personCredentials, CredentialTypeEnum.Address)?.address;
  const nationality = getCredentialPayload(personCredentials, CredentialTypeEnum.Nationality)?.nationality;

  const authorizedRepresentative = authRepCredential?.payload.roleName;

  // org VCs
  const orgAddress = [...requiredCompanyCredentials]
    .sort((a, b) => b.updatedAt - a.updatedAt)
    .find((c) => c.type === CredentialTypeEnum.Address && c.did.includes('org'))
    ?.payload.address;
  const legalName = companyCredentials.find(
    (c) => c.type === CredentialTypeEnum.LegalName
  )?.payload.legalName;

  return (
    <div>
      {givenName ||
      familyName ||
      birthdate ||
      personalAddress ||
      nationality ? (
        <PersonCredentialAccordion
          givenName={givenName}
          familyName={familyName}
          birthdate={birthdate}
          personalAddress={personalAddress}
          nationality={nationality}
          key='person'
          hostname={hostname}
        />
      ) : (
        <VerifiedAccountAccordion
          key='verifiedAccount'
          hostname={hostname}
        />
      )}

      {(authorizedRepresentative || orgAddress || legalName) && (
        <OrgCredentialAccordion
          authorizedRepresentative={authorizedRepresentative}
          orgAddress={orgAddress}
          legalName={legalName}
          key='authorizedRepresentative'
          hostname={hostname}
        />
      )}
    </div>
  );
};
