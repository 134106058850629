import { Grid, Typography } from '@material-ui/core';
import * as countries from 'i18n-iso-countries';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import {IdentRequestDtoSelectedIdentProviderEnum} from "../../api-clients/core-service-api-react";
import supportedDocs from '../../assets/supportedDocs.json';

interface Props {
  identProvider: string;
}

export const SupportedDocs = ({ identProvider }: Props) => {
  const { t } = useTranslation();
  const locale = 'de';
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

  let supportedDocsByIdentProvider;
  if (identProvider === IdentRequestDtoSelectedIdentProviderEnum.NectAutoident.valueOf()) {
    supportedDocsByIdentProvider = supportedDocs.nect_autoident;
  } else {
    supportedDocsByIdentProvider = supportedDocs.itm_videoident;
  }

  const supportedIdCardsByCountry = supportedDocsByIdentProvider.idcard
    .map((country) => countries.getName(country, locale))
    .sort((a, b) => a.localeCompare(b, locale))
    .map((country) => countries.getAlpha2Code(country, locale));
  const supportedPassportsByCountry = supportedDocsByIdentProvider.passport
    .map((country) => countries.getName(country, locale))
    .sort((a, b) => a.localeCompare(b, locale))
    .map((country) => countries.getAlpha2Code(country, locale));
  const supportedResidencePermitsByCountry =
    supportedDocsByIdentProvider.residencepermit
      .map((country) => countries.getName(country, locale))
      .sort((a, b) => a.localeCompare(b, locale))
      .map((country) => countries.getAlpha2Code(country, locale));
  return (
    <Grid container justifyContent='flex-start' alignItems='flex-start'>
      <Grid item xs={4}>
        <Typography align='left' variant='subtitle1'>
          {t('supportedDocs.passport')}
        </Typography>
        {supportedPassportsByCountry.map((country) => (
          <Grid item xs={12}>
            <Typography align='left' variant='body2'>
              <ReactCountryFlag
                countryCode={country}
                svg
                title={country}
                style={{ marginRight: '10px' }}
              />
              {countries.getName(country, locale)}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={4}>
        <Typography align='left' variant='subtitle1'>
          {t('supportedDocs.idcard')}
        </Typography>
        {supportedIdCardsByCountry.map((country) => (
          <Grid item xs={12}>
            <Typography align='left' variant='body2'>
              <ReactCountryFlag
                countryCode={country}
                svg
                title={country}
                style={{ marginRight: '10px' }}
              />
              {countries.getName(country, locale)}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={4}>
        <Typography align='left' variant='subtitle1'>
          {t('supportedDocs.residencepermit')}
        </Typography>
        {supportedResidencePermitsByCountry.map((country) => (
          <Grid item xs={12}>
            <Typography align='left' variant='body2'>
              <ReactCountryFlag
                countryCode={country}
                svg
                title={country}
                style={{ marginRight: '10px' }}
              />
              {countries.getName(country, locale)}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
