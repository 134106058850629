import { useState } from 'react';

import { Grid, Hidden, makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { NoRedirect } from './NoRedirect';
import { UserInfo } from './VerificationInformations';
import { WaitForVerification } from './WaitForVerification';
import {
  CompanyRequest,
  CredentialTypeEnum,
  PersonRequest,
  PersonRequestStateEnum
} from "../../api-clients/core-service-api-react";
import crefoTheme, { crefoColors, defaultTheme } from '../../CrefoTheme';
import { CrefoRoute } from '../../interfaces/crefo-route.interface';
import { RequestResult } from '../../interfaces/request-result.interface';
import { useAppSelector } from '../../state/hooks';
import {
  selectCompanyCredentials,
  selectFlavor,
  selectPersonCredentials,
  selectVerifierName,
} from '../../state/ident/identSlice';
import { IdentProvider } from '../CredentialsInsufficient/IdentProvider';
import { DynamicHeightContainer } from '../DynamicHeightContainer/DynamicHeightContainer';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { IdentityCard } from '../IdentityCard/IdentityCard';
import { Navigation } from '../Navigation/Navigation';

const verficationResultStyles = makeStyles({
  contentContainer: {
    [defaultTheme.breakpoints.down('sm')]: {
      marginTop: '0px !important',
      minHeight: 'calc(100vh - 270px)',
    },
  },
  contentHeight: {
    [defaultTheme.breakpoints.down('sm')]: {
      minHeight: '50vh',
    },
  },
  mailToSupportArrow: {
    fontSize: 16,
    marginTop: 4,
    marginRight: 6,
    color: crefoColors.primary,
  },
  infoTextMargin: {
    marginTop: '30px',
    marginBottom: '60px',
  },
  gridContainerMargin: {
    marginTop: '3%',
  },
  verificationSuccessful: {
    paddingTop: 30,
  },
  verificationSuccessfulIcon: {
    fontSize: 35,
    marginLeft: 10,
    color: crefoColors['c-s-3'],
  },
});

const getEnumKeyByEnumValue = <T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string,
  fallback: string
): keyof T => {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : fallback;
};

export const VerificationResult = () => {
  const classes = verficationResultStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [stepNumber] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [requestResult, setRequestResult] = useState<RequestResult>();
  const [secondaryInfo, setSecondaryInfo] = useState(false);

  const verifierName = useAppSelector(selectVerifierName);
  const personCredentials = useAppSelector(selectPersonCredentials);
  const companyCredentials = useAppSelector(selectCompanyCredentials);

  const flavor = useAppSelector(selectFlavor);

  const isAuthorizedRepresentative = async (): Promise<boolean> => {
    const companyDid = companyCredentials[0]?.did;
    return personCredentials.filter(
        (el) => {
          const type = el.type;
          const payload = el.payload as any;
          return type === CredentialTypeEnum.AuthorizedRepresentative &&
              payload.worksFor === companyDid
        }
      ).length >= 1;
  };

  const verificationHappyCase = () => {
    setRequestResult(RequestResult.SUCCESS);
    setLoading(false);
  };

  const missingAuthorizedRepresentativeCase = () => {
    setRequestResult(RequestResult.MISSING_AUTHORIZED_REPRESENTATIVE);
    setLoading(false);
  };

  const otherErrorCase = (stateReason: RequestResult) => {
    setRequestResult(stateReason);
    setLoading(false);
  };

  const onFinishCallback = async (request: PersonRequest | CompanyRequest | undefined) => {
    if (flavor === 'B2C' && !request) {
      verificationHappyCase();
      return;
    }
    if (
      request?.type === 'org' &&
      request?.state === PersonRequestStateEnum.Inprogress
    ) {
      missingAuthorizedRepresentativeCase();
      return;
    }
    if (
      request?.type === 'org' &&
      request?.state === PersonRequestStateEnum.Accepted
    ) {
      verificationHappyCase();
      return;
    }

    // Unexpected Error during request of person credential
    if (
      request?.stateReason !== IdentProvider.NectAutoIdent &&
      request?.stateReason !== IdentProvider.ItmVideoIdent &&
      request?.stateReason !== IdentProvider.DID
    ) {
      const key = getEnumKeyByEnumValue(
        RequestResult,
        request?.stateReason ?? '',
        'UNKNOWN_PROBLEM'
      );
      otherErrorCase(RequestResult[key]);
      return;
    }

    if ((await isAuthorizedRepresentative()) || flavor === 'B2C') {
      verificationHappyCase();
    } else {
      missingAuthorizedRepresentativeCase();
    }
  };

  return (
    <ThemeProvider theme={crefoTheme}>
      <DynamicHeightContainer>
        <Header
          targetPlatform={verifierName}
          activeStep={stepNumber}
          showTargetPlatformLink
          showStepper
        />
        <Grid
          id='content'
          container
          className={`container marginTop ${classes.contentContainer}`}
        >
          <Grid
            container
            item
            lg={12}
            className={classes.contentHeight}
            /* Content Area */
          >
            <Grid container item lg={8} /* Content */>
              {isLoading ? (
                <WaitForVerification
                  onFinishCallback={onFinishCallback}
                  openSecondaryInfo={() => {
                    setSecondaryInfo(true);
                  }}
                />
              ) : (
                <UserInfo requestResult={requestResult} />
              )}
            </Grid>
            <Grid container item lg={4} justifyContent='flex-end' /* Perso */>
              <Hidden mdDown>
                <Grid item>
                  <IdentityCard idResult={requestResult} animated={false} />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent='center' /* Navigation Container */
        >
          <Navigation
            requestResult={requestResult}
            nextButtonText={t('showVerificationResult.gotoDestination')}
            nextButtonLink={CrefoRoute.APPROVE_CREDENTIALS}
            onClick={() => {
              navigate(CrefoRoute.APPROVE_CREDENTIALS);
            }}
            disabled={isLoading}
            showBackButton={
              requestResult ===
                RequestResult.MISSING_AUTHORIZED_REPRESENTATIVE ||
              RequestResult.ORDER_PRESETS_MISMATCH ||
              requestResult === RequestResult.UNKNOWN_PROBLEM ||
              requestResult === RequestResult.UNEXPECTED_CANCELLATION ||
              requestResult === RequestResult.NAME_MISMATCH ||
              requestResult === RequestResult.TECHNICAL_PROBLEM
            }
            showContinueButton={
              requestResult !==
                RequestResult.MISSING_AUTHORIZED_REPRESENTATIVE &&
              requestResult !== RequestResult.ORDER_PRESETS_MISMATCH
            }
          />
          <Footer />
        </Grid>
        {secondaryInfo ? (
          <NoRedirect
            targetPlatform={verifierName}
            onClose={() => {
              setSecondaryInfo(false);
            }}
          />
        ) : null}
      </DynamicHeightContainer>
    </ThemeProvider>
  );
};
