import {Company, Configuration, FirmenwissenApi, Manager} from "../../api-clients/core-service-api-react";
import { Signatory } from "../../interfaces/signatories";
import { getContext, getDefaultHeaders } from "../../utils/helpers";
import { RootState } from '../store';

const apiConfig= new Configuration({
  basePath: window?._env_?.CREFO_TRUSTEE_API ?? '',
})
const api = new FirmenwissenApi(apiConfig)

async function searchCompany(
  token: string,
  state: RootState,
  company: string
): Promise<Company[]> {
  const headers =  getDefaultHeaders(token);
  const { orderId, did } = getContext(state);

  const result = await api.firmenwissenGatewayControllerSearchCompany(company, did, orderId, {
    headers,
  });

  return result.data;
}

async function searchManager(
  token: string,
  state: RootState,
  companyCrefoId: string
): Promise<Manager[]> {
  const headers =  getDefaultHeaders(token);
  const { orderId, did } = getContext(state);

  const result = await api.firmenwissenGatewayControllerSearchManager(companyCrefoId, did, orderId, { headers });

  console.log('RESULT:', result.data);

  return result.data;
}

export { searchCompany, searchManager };
