import {useEffect, useState} from 'react';

import { Typography, CircularProgress, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  CompanyRequest, Credential,
  CredentialTypeEnum,
  PersonRequest,
  PersonRequestStateEnum
} from "../../api-clients/core-service-api-react";
import { waitForVerificationStyles } from '../../CrefoTheme';
import {
  ChosenCompany,
  ChosenSignatory,
} from '../../interfaces/request.interface';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  getIdentState,
  selectFlavor,
  selectLatestCompanyRequest,
  selectLatestRequest,
  selectPersonCredentials,
  selectRequiredCredentialTypes,
} from '../../state/ident/identSlice';
import {
  selectChosenCompany,
  selectChosenSignatory,
  updateChosenCompany,
  updateChosenSignatory,
} from '../../state/input/inputSlice';
import {getCredentialPayload} from "../../utils/helpers";

interface Props {
  onFinishCallback: (req: PersonRequest | CompanyRequest | undefined) => Promise<void>;
  openSecondaryInfo: () => void;
}

export const WaitForVerification = ({
  onFinishCallback,
  openSecondaryInfo,
}: Props) => {
  const { t } = useTranslation();
  const classes = waitForVerificationStyles();
  const dispatch = useAppDispatch();

  const personCredentials = useAppSelector(selectPersonCredentials);
  const chosenCompany = useAppSelector(selectChosenCompany);
  const chosenSignatory = useAppSelector(selectChosenSignatory);
  const setChosenCompany = (company: ChosenCompany) =>
    dispatch(updateChosenCompany(company));
  const setChosenSignatory = (signatory: ChosenSignatory) =>
    dispatch(updateChosenSignatory(signatory));
  const latestRequest = useAppSelector(selectLatestRequest);
  const latestCompanyRequest = useAppSelector(selectLatestCompanyRequest);
  const flavor = useAppSelector(selectFlavor);
  const requiredCredentialTypes = useAppSelector(selectRequiredCredentialTypes);

  const intervalTimer: number = 5000;

  const userHasPersonCredentials = (credentials: Credential[]): boolean => credentials.length > 0
  const getSignatoryFromPersonCredentials = (credentials: Credential[]): ChosenSignatory => {
    const { givenName, familyName } = getCredentialPayload(credentials, CredentialTypeEnum.Name) ?? { givenName: '', familyName: '' };
    const birthDate = getCredentialPayload(credentials, CredentialTypeEnum.Birthdate)?.birthdate;
    const crefoId = getCredentialPayload(credentials, CredentialTypeEnum.CrefoId)?.crefoId;
    const address = getCredentialPayload(credentials, CredentialTypeEnum.Address)?.address;

    return {
      id: '',
      givenName,
      familyName,
      birthDate,
      crefoId,
      address,
    }
  }

  const stopPolling = (interval: NodeJS.Timer, timeout: NodeJS.Timer) => {
    clearInterval(interval);
    clearTimeout(timeout);
  }

  useEffect(() => {
    const interval = setInterval(() => dispatch(getIdentState()), intervalTimer);
    let timeout = setTimeout(() => {}, 0);

    if (userHasPersonCredentials(personCredentials)) {
      setChosenSignatory(getSignatoryFromPersonCredentials(personCredentials));
    }
    /** WHY TF is there any calculation in the FE? */
    if (flavor === 'B2C') {
      const receivedCredentialTypes = personCredentials.map((cred) => cred.type).sort();
      const userReceivedAllRequiredCredentialTypes =  JSON.stringify(requiredCredentialTypes?.person?.sort()) === JSON.stringify(receivedCredentialTypes)


      if (userReceivedAllRequiredCredentialTypes) {
        stopPolling(interval, timeout);
        timeout = setTimeout(() => onFinishCallback(undefined), intervalTimer);
      }
    }

    /** WHY TF is there any calculation in the FE? */
    if (
      latestRequest &&
      (latestRequest.state === PersonRequestStateEnum.Accepted ||
        latestRequest.state === PersonRequestStateEnum.Rejected ||
        latestRequest.state === PersonRequestStateEnum.CustomerPostprocessing)
    ) {
      stopPolling(interval, timeout);
      timeout = setTimeout(() => onFinishCallback(latestRequest), intervalTimer);

      if (
        latestRequest?.orgRequest?.payload &&
        chosenCompany?.legalName !==
          latestRequest?.orgRequest?.payload?.legalName
      ) {
        setChosenCompany(latestRequest.orgRequest.payload);
      }

      if (
        chosenSignatory?.givenName !== latestRequest?.payload?.givenName ||
        chosenSignatory?.familyName !== latestRequest?.payload?.familyName
      ) {
        setChosenSignatory({ ...latestRequest.payload, id: '' });
      }
    }

    if (latestCompanyRequest) {
      if (latestCompanyRequest.state === PersonRequestStateEnum.Open) {
        dispatch(getIdentState());
      } else {
        stopPolling(interval, timeout);
        // TODO: Extend type CredentialRequest to also support CompanyRequests
        const payload: ChosenCompany = latestCompanyRequest.payload;
        setChosenCompany(payload);
        setTimeout(
          () => onFinishCallback(latestCompanyRequest),
          intervalTimer
        );
      }
    }
    return () => clearInterval(interval);
  }, [personCredentials, latestRequest, latestCompanyRequest]);

  return (
    <>
      <div className={classes.container}>
        <h2 className={classes.headline}>{t('waitForVerification.title')}</h2>
        <Typography className={classes.text} align='left'>
          {t('waitForVerification.text')}
        </Typography>
      </div>
      <Hidden lgUp>
        <Typography className={classes.secondaryInfo}>
          <a href='#' onClick={openSecondaryInfo}>
            {t('waitForVerification.linkText')}
          </a>
        </Typography>
      </Hidden>
      <CircularProgress size={80} className={classes.spinner} />
      <Hidden mdDown>
        <Typography style={{ position: 'absolute', bottom: 200 }}>
          <a href='#' onClick={openSecondaryInfo}>
            {t('waitForVerification.linkText')}
          </a>
        </Typography>
      </Hidden>
    </>
  );
};
