import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { searchCompany, searchManager } from './inputApi';
import {Company, Manager} from "../../api-clients/core-service-api-react";
import {
  inputSliceName,
  InputState,
  InputStatus,
  ReduxStateStatus,
} from '../../interfaces/redux-states.interface';
import {
  ChosenCompany,
  ChosenSignatory,
} from '../../interfaces/request.interface';
import { Signatory } from "../../interfaces/signatories";
import {mapToSignatory} from "../../utils/get-signatory";
import {getUser} from "../../utils/get-user.helper";
import { CrefoThunkConfig, RootState } from '../store';

const initialState: InputState = {
  companies: [],
  signatories: [],
  status: ReduxStateStatus.INITIAL,
  inputStatus: InputStatus.INPUT_INITIAL,
  submitted: false,
  manualReVerification: false,
  manualCompanyAddressCorrection: false,
};

export const getCompanies = createAsyncThunk<
  Company[],
  string,
  CrefoThunkConfig
>(
  `${inputSliceName}/getCompanies`,
  async (company, thunkAPI): Promise<Company[]> => {
    const {
      getState,
    } = thunkAPI;

    const state = getState();
    const user = getUser(state.ident.oidcConfig?.authority, state.ident.oidcConfig?.client_id);
    const token = user?.access_token;
    // await refreshLogin(state);
    return await searchCompany(token ?? '', state, company);
  }
);

export const getSignatories = createAsyncThunk<
  Signatory[],
  string,
  CrefoThunkConfig
>(
  `${inputSliceName}/getSignatories`,
  async (companyCrefoId, thunkAPI): Promise<Signatory[]> => {
    const {
      getState,
    } = thunkAPI;
    const state = getState();
    const user = getUser(state.ident.oidcConfig?.authority, state.ident.oidcConfig?.client_id);
    const token = user?.access_token;

    const managers = await searchManager(
      token ?? '',
      state,
      companyCrefoId
    );

    return managers.map((manager: Manager) => mapToSignatory(manager));
  }
);

export const manuallyReVerify = createAsyncThunk<
  boolean,
  boolean,
  CrefoThunkConfig
>(
  `${inputSliceName}/manuallyReVerify`,
  async (enable, thunkAPI): Promise<boolean> => {
    const {
      getState,
    } = thunkAPI;
    const state = getState();
    // await refreshLogin(state);
    return enable;
  }
);

export const inputSlice = createSlice({
  name: inputSliceName,
  initialState,
  reducers: {
    clearInput: (state, action: PayloadAction<void>) => {
      state.companies = [];
      state.signatories = [];
      state.chosenCompany = undefined;
      state.chosenSignatory = undefined;
      state.status = ReduxStateStatus.SUCCESS;
      state.inputStatus = InputStatus.RESET;
    },
    updateChosenCompany: (state, action: PayloadAction<ChosenCompany>) => {
      state.chosenCompany = action.payload;
      state.status = ReduxStateStatus.SUCCESS;
      state.inputStatus = InputStatus.COMPANY_CHOSEN;
    },
    updateChosenSignatory: (state, action: PayloadAction<ChosenSignatory>) => {
      if (
        state.inputStatus !== InputStatus.COMPANY_CHOSEN &&
        state.inputStatus !== InputStatus.SIGNATORY_CHOSEN
      ) {
        state.status = ReduxStateStatus.ERROR;
      } else {
        state.chosenSignatory = action.payload;
        state.status = ReduxStateStatus.SUCCESS;
        state.inputStatus = InputStatus.SIGNATORY_CHOSEN;
      }
    },
    noCompanyFound: (state, action: PayloadAction<void>) => {
      state.companies = [
        {
          legalName: '...---notYourCompany...---',
          crefoId: '',
          address: {
            streetAddress: '',
            postalCode: '',
            addressLocality: '',
            addressCountry: '',
          },
        },
      ];
      state.status = ReduxStateStatus.SUCCESS;
    },
    goToIdentService: (state, action: PayloadAction<void>) => {
      state.submitted = true;
    },
    returnFromIdentService: (state, action: PayloadAction<void>) => {
      state.submitted = false;
    },
    manuallyCorrectCompanyAddress: (state, action: PayloadAction<boolean>) => {
      state.manualCompanyAddressCorrection = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.status = ReduxStateStatus.LOADING;
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.status = ReduxStateStatus.SUCCESS;
      })
      .addCase(getCompanies.rejected, (state) => {
        state.status = ReduxStateStatus.ERROR;
      })
      .addCase(getSignatories.pending, (state) => {
        state.status = ReduxStateStatus.LOADING;
      })
      .addCase(getSignatories.fulfilled, (state, action) => {
        state.signatories = action.payload;
        state.status = ReduxStateStatus.SUCCESS;
      })
      .addCase(getSignatories.rejected, (state) => {
        state.status = ReduxStateStatus.ERROR;
      })
      .addCase(manuallyReVerify.pending, (state) => {
        state.status = ReduxStateStatus.LOADING;
      })
      .addCase(manuallyReVerify.fulfilled, (state, action) => {
        state.manualReVerification = action.payload;
        state.status = ReduxStateStatus.SUCCESS;
      })
      .addCase(manuallyReVerify.rejected, (state) => {
        state.status = ReduxStateStatus.ERROR;
      });
  },
});

export const {
  updateChosenCompany,
  updateChosenSignatory,
  clearInput,
  noCompanyFound,
  goToIdentService,
  returnFromIdentService,
  manuallyCorrectCompanyAddress,
} = inputSlice.actions;

export const selectChosenSignatory = (state: RootState) =>
  state.input.chosenSignatory;
export const selectChosenCompany = (state: RootState) =>
  state.input.chosenCompany;
export const selectCompanies = (state: RootState) => state.input.companies;
export const selectSignatories = (state: RootState) => state.input.signatories;
export const selectInputStatus = (state: RootState) => state.input.inputStatus;
export const selectStatus = (state: RootState) => state.input.status;
export const selectSubmitted = (state: RootState) => state.input.submitted;
export const selectManualReVerification = (state: RootState) =>
  state.input.manualReVerification;
export const selectManualCompanyAddressCorrection = (state: RootState) =>
  state.input.manualCompanyAddressCorrection;

export const inputReducer = inputSlice.reducer;
