import { Button, SvgIcon } from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ReactComponent as ArrowNext } from '../../assets/images/icons/arrow_next.svg';
import { CrefoRoute } from '../../interfaces/crefo-route.interface';
import { NavigationStyles } from './Navigation.styles';

export const BackToCompanySearchButton = ({
  disabled,
  buttonWidth,
  loading,
}: any) => {
  const classes = NavigationStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      {loading ? (
        <LoadingButton
          data-testid='continueBtn'
          className={classes.nextButtonLoading}
          pending
          style={buttonWidth ? { width: buttonWidth } : { width: '100%' }}
        />
      ) : (
        <Button
          data-testid='continueBtn'
          disabled={disabled}
          onClick={() => navigate(CrefoRoute.COMPANY_SEARCH)}
          style={buttonWidth ? { width: buttonWidth } : { width: '100%' }}
          endIcon={<SvgIcon component={ArrowNext} style={{ marginTop: 10 }} />}
          className={classes.nextButton}
        >
          {t('showVerificationResult.repeatVerification')}
        </Button>
      )}
    </>
  );
};
